import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./collapsBlock.scss";
import bgImage from "../../assets/images/result_details_top.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonInput from "../button";
import { newTest, setUserData } from "../../redux/action/user";
import Skeleton from "@mui/material/Skeleton";
import { InputLabel } from "@mui/material";

const CollapsBlock = (props) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [expandedList, setExpandedList] = useState([]);

  // const showSkeletons =
  //   (props.user.programPurchased && props.user.programPurchased?.length > 0) ||
  //   (props.user.subscription && props.user.subscription.isSubscrption === true)
  //     ? false
  //     : true;

  // const lowesttoHightest = props.data
  //   ? props.data.sort((a, b) => a.score - b.score)
  //   : [];

  const Resume =
    (props.user.surveyIdList &&
      props.user.surveyIdList[props.user.surveyIdList.length - 1]
        .s_IsSurveyCompleted !== 1) ||
    false;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setExpandedList(props.data.map((e) => e.ID));
    }
  }, [props.data]);

  useEffect(() => {
    const host = window.location.host;
    if (
      host === "localhost:3000" ||
      host.search("demo") !== -1 ||
      props.deleteVisible
    ) {
      setShowDeleteButton(true);
    }
  }, []);

  const newAssessment = () => {
    if (Resume) {
      localStorage.removeItem("progressDone");
      localStorage.removeItem("detailDone");
      localStorage.setItem(
        "assesmentKey",
        props.user.surveyIdList[props.user.surveyIdList.length - 1].SurveyId
      );
      dispatch(
        setUserData({
          surveyId:
            props.user.surveyIdList[props.user.surveyIdList.length - 1]
              .SurveyId,
        })
      );
      navigate("/progress");
    } else {
      props.newTest(props.user._id, navigate);
    }
  };

  return (
    <div>
      <div className="top-result-details">
        <img src={bgImage} alt={bgImage}></img>
        <div className="ab-top-result-details">
          <p>score by category</p>
          {/* <div className="delete-button-div">
            {showDeleteButton && (
              <ButtonInput
                class="delete-clear-button"
                text={"Clear And Restart"}
                onSubmit={() => props.deleteUserData && props.deleteUserData()}
              />
            )}
          </div> */}
          {/* <div>
            <ButtonInput
              class="delete-clear-button"
              text={"Review Assessment"}
              onSubmit={() => {
                localStorage.removeItem("progressDone");
                localStorage.removeItem("detailDone");
                navigate("/progress");
              }}
            />
          </div> */}
          <div style={{ paddingRight: "18px" }}>
            <ButtonInput
              class="delete-clear-button"
              text={`${Resume ? "Resume" : "New Assessment"}`}
              onSubmit={() => newAssessment()}
            />
          </div>
        </div>
      </div>
      <div className="inner-bg-result">
        <div className="sub-header">
          <p>
            Click on each tip to learn more
            {/* The Japa Health Method is Based on the {props.data.length} Determinants of Health */}
          </p>
        </div>
        <div>
          {props.data &&
            props.data.map((obj, index) => {
              return (
                <Accordion expanded={expandedList.includes(obj.ID)} key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => {
                      setExpandedList((prev) => {
                        const temp = Array.from(prev);
                        if (temp.includes(obj.ID)) {
                          temp.splice(temp.indexOf(obj.ID), 1);
                        } else {
                          temp.push(obj.ID);
                        }

                        return temp;
                      });
                    }}
                  >
                    <Typography
                      sx={{ flexShrink: 0 }}
                      style={{ display: "flex", gap: 10, alignItems: "center" }}
                    >
                      <div className="image-container">
                        {obj?.Icon && (
                          <img
                            src={require(`../../assets/images/determine${obj?.Icon}`)}
                            height={50}
                            minWidth={50}
                          />
                        )}
                      </div>
                      <div>{obj.Determinant}</div>
                    </Typography>
                    <Typography
                      className="avenir-bold"
                      sx={{ color: "text.secondary" }}
                      style={{ marginRight: "10px" }}
                    >
                      Scored -{" "}
                      {obj.score ? (
                        obj.score
                      ) : (
                        <Skeleton
                          variant="text"
                          width="100%"
                          height={20}
                          sx={{ backgroundColor: "aliceblue" }}
                        />
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {obj.isGetSuggestion ? (
                      <>
                        <div className="yellow-font tooltip-title">
                          Tips to Improve
                        </div>
                        <ul>
                          {obj.suggestion && obj.suggestion.length > 0 ? (
                            obj.suggestion.map((suggestionObj, subIndex) => {
                              return (
                                <li>
                                  <a
                                    onClick={() => {
                                      if (props.modelOpen) {
                                        props.modelOpen(suggestionObj);
                                      }
                                    }}
                                  >
                                    {suggestionObj.Suggestion}
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <Fragment>
                              <div className="yellow-font tooltip-title">
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={20}
                                  sx={{ backgroundColor: "aliceblue" }}
                                />
                              </div>
                              <ul>
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={20}
                                  sx={{ backgroundColor: "aliceblue" }}
                                />
                              </ul>
                              <ul>
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={20}
                                  sx={{ backgroundColor: "aliceblue" }}
                                />
                              </ul>
                              <ul>
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={20}
                                  sx={{ backgroundColor: "aliceblue" }}
                                />
                              </ul>
                              <ul>
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height={20}
                                  sx={{ backgroundColor: "aliceblue" }}
                                />
                              </ul>
                              <InputLabel
                                style={{
                                  cursor: "pointer",
                                  paddingRight: "30px",
                                  paddingTop: "10px",
                                  // color: "white",
                                  animation:
                                    "animation-c7515d 1.5s ease-in-out 0.5s infinite",
                                  textDecoration: "underline",
                                  color: "#E29C2E",
                                }}
                                onClick={() => navigate("/plans")}
                              >
                                <Link
                                  to={""}
                                  style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                  }}
                                >
                                  {" "}
                                  To view the details, please click here to
                                  purchase a subscription.
                                </Link>
                              </InputLabel>
                            </Fragment>
                          )}
                        </ul>
                      </>
                    ) : (
                      <ul>
                        <p className="cus-label">{obj.suggestion}</p>
                      </ul>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      </div>
    </div>
  );
};

CollapsBlock.propTypes = {};
CollapsBlock.defaultProps = {};

const actionCreators = {
  newTest,
  setUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(CollapsBlock);
