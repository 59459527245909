import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import headerImage from "../../../assets/images/header.png";
import bgImage from "../../../assets/images/result-bg-header.png";
import { InputLabel } from "@mui/material";
import "./history.scss";

const HistoryHeader = (props) => {
  const navigate = useNavigate();

  function signout() {
    localStorage.clear("userKey");
    localStorage.clear("_grecaptcha");
    localStorage.removeItem("review");
    sessionStorage.clear("_grecaptcha");
    // console.log(">>>>>>>>>>>>>>>>>Logging out user<<<<<<<<<<<<<<<<<<<<<");
    navigate("/signin");
    alert(
      "Hi! Your session has expired due to inactivity. Please Sign in again to continue the assessment"
    );
    window.location.reload();
  }

  return (
    <div className="result-header-section">
      <div className="ab-bg-image">
        <img src={bgImage} alt={bgImage}></img>
      </div>
      <div className="result-header-section__fixed bg-transparent border-none">
        <div className="gridContainer">
          <div className="inner-result-header__fixed">
            <div>
              <img src={headerImage} alt={headerImage}></img>
            </div>
            <div className="cus-label result-header-section__right-side">
              <div>
                {/* <img className='right-side-image' src={userThumb} alt={userThumb} ></img> */}
              </div>
              <div className="result-header-section__right-side--logout">
                <div
                  style={{ cursor: "pointer", paddingRight: "30px" }}
                  onClick={() => navigate("/plans")}
                >
                  Plans & subscription
                </div>
              </div>
              <div className="result-header-section__right-side--logout">
                <div
                  style={{
                    cursor: "pointer",
                    // color: "white",
                    paddingRight: "30px",
                  }}
                  onClick={() => signout()}
                >
                  Logout
                </div>
              </div>
              <div className="result-header-section__right-side--userdetail">
                <div>Welcome</div>
                <div>{props.user.firstName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HistoryHeader.propTypes = {};
HistoryHeader.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(HistoryHeader);
