import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { updateUserData } from "../../redux/action/user";
import { connect } from "react-redux";
import { Button, Typography, Box } from "@mui/material";

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    setMessage(null);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
    });

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("Payment successful!");
      // Optionally, dispatch the user data update or redirect here
    }

    setIsProcessing(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <CardElement />
      <PaymentElement id="payment-element" />
      <Button
        variant="contained"
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        type="submit"
        className="delete-clear-button"
      >
        {" "}
        {isProcessing ? "Processing..." : "Pay Now"}
      </Button>
      {message && (
        <Typography
          variant="body1"
          color={message.includes("successful") ? "green" : "red"}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

const actionCreators = {
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(PaymentForm);
