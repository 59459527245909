import React, { useEffect, useState } from "react";
import "./resultBody.scss";
import { connect } from "react-redux";
import StarsRating from "stars-rating";
import { useNavigate } from "react-router-dom";
import IosShareIcon from "@mui/icons-material/IosShare";
import CollapsBlock from "../../../atoms/collapsBlock";
import { IconButton, InputLabel, Modal } from "@mui/material";
import { Box } from "@mui/system";
import closex from "../../../assets/images/x.png";
import heartTick from "../../../assets/images/heart-tick.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "./gradientSVG";
import {
  getDeterminants,
  getUserData,
  deleteUserResponse,
  updateSuggestion,
  updateReference,
} from "../../../redux/action/user";
import ButtonInput from "../../../atoms/button";
import MobileShareButton from "../../../atoms/button/mobileShareButton";
import CenterLoading from "../../core/CenterLoading";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ResultBody = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [starValue, setStarValue] = useState(0);
  const [imageToShare, setImageToShare] = useState();
  const navigate = useNavigate();

  const setImageBase64 = async () => {
    const getBase64StringFromDataURL = (dataURL) =>
      dataURL.replace("data:", "").replace(/^.+,/, "");

    const image = await (await fetch("/demo-share-image.png")).blob();

    setImageToShare(
      new File([image], "score_share.png", {
        type: "image/png",
      })
    );

    const reader = new FileReader();

    reader.onloadend = () => {
      // Convert to Base64 string
      const base64 = getBase64StringFromDataURL(reader.result);
    };
    reader.readAsDataURL(image);
  };

  useEffect(() => {
    props.getDeterminants();
    setImageBase64();
  }, []);

  const sortUserScores = () => {
    const sorted = (props.user.determinantList ?? [])
      .map((e) => {
        return {
          ...e,
          score: parseInt(e.score),
        };
      })
      .sort((a, b) => {
        if (b.score === a.score) return 0;
        if (b.score < a.score) return -1;
        return 1;
      });

    return sorted;
  };

  useEffect(() => {
    if (props.user.determinantList && props.user.determinantList.length > 0) {
      setStarValue(
        props.user.userFinalScore >= 90
          ? 5
          : props.user.userFinalScore >= 78 && props.user.userFinalScore <= 89
          ? 4
          : props.user.userFinalScore >= 68 && props.user.userFinalScore <= 77
          ? 3
          : props.user.userFinalScore >= 50 && props.user.userFinalScore <= 67
          ? 2
          : 1
      );
    }
  }, [props.user.determinantList]);

  const onSubmitQuestion = (tempModalData) => {
    setOpen(!open);
    setModalData(tempModalData);
    // console.log(tempModalData?.suggestionID);
    if (props.user._id && tempModalData?.suggestionID) {
      props.updateSuggestion(props.user._id, tempModalData.suggestionID);
    }
  };

  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  const deleteUserData = () => {
    if (props.user._id) {
      props.deleteUserResponse(props.user._id, props.user.surveyId, navigate);
      setDeleteModal(false);
    }
  };


  const sortedData = sortUserScores();
  const highest = sortedData[0];
  const lowest = sortedData[sortedData.length - 1];

  const urlToShare = window.location.host.toLowerCase().includes("vitality")
    ? "https://vitality.japa.health"
    : "https://demo.japa.health/";
  // const urlToShare = "https://www.youtube.com/watch?v=Gmw_kcQT1Gg";

  const titleToShare = `I just found out I’m really great at ${highest?.Determinant}, and need some work on ${lowest?.Determinant}. Crack YOUR code at `;

  return (
    <div className="result-body result-body">
      <CenterLoading
        open={props.user.isLoading}
        showSuggestions
        suggestionList={[
          props.user.language?.loading_suggestions?.sugg_0,
          props.user.language?.loading_suggestions?.sugg_1,
          props.user.language?.loading_suggestions?.sugg_2,
          props.user.language?.loading_suggestions?.sugg_3,
          props.user.language?.loading_suggestions?.sugg_4,
        ]}
      />
      <div className="result-body__fixed bg-transparent">
        <div className="gridContainer">
          <div
            className="white"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2>Hi {props.user.firstName},</h2>
              <div style={{ marginLeft: "10px" }}>Check Your Score here 😍</div>
            </div>
          </div>

          <div
            className="flex-column"
            style={{ display: "flex", marginTop: "20px" }}
          >
            <div className="chart-block">
              <div className="chart-title">
                <h2 className="white-color avenir-bold">Your Vitality Score</h2>
              </div>
              <GradientSVG />
              <div className="chart-detail">
                <div className="chart-detail__chart">
                  <CircularProgressbar
                    value={props.user.userFinalScore}
                    strokeWidth={8}
                    text={`${props.user.userFinalScore}`}
                    styles={buildStyles({
                      rotation: 1 / 2,
                      pathColor: `url(#hello)`,
                      trailColor: "#264448",
                      textColor: "#fff",
                    })}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </div>
              <div className="score-result">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <p className='white-color avenir-bold'>
                      Your overall score is
                  </p>
                  <p className='avenir-bold' style={{ color: 'green', marginLeft: '5px' }}>
                      Good 🤗
                  </p> */}
                  <StarsRating
                    count={5}
                    value={starValue}
                    edit={false}
                    size={35}
                    color2={"#ffd700"}
                  />
                </div>
              </div>
              <div className="shareButton">
                <br />
                <div className="web-share-buttons">
                  <FacebookShareButton
                    url={urlToShare}
                    quote={titleToShare + `${urlToShare}`}
                    title={titleToShare + `${urlToShare}`}
                    windowHeight={800}
                    windowWidth={1200}
                  >
                    <FacebookIcon round size={36} />
                  </FacebookShareButton>
                  <WhatsappShareButton
                    title={titleToShare}
                    url={urlToShare}
                    windowHeight={800}
                    windowWidth={1200}
                  >
                    <WhatsappIcon round size={36} />
                  </WhatsappShareButton>

                  <TwitterShareButton
                    url={urlToShare}
                    title={titleToShare}
                    windowHeight={800}
                    windowWidth={1200}
                  >
                    <TwitterIcon round size={36} />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={urlToShare}
                    title={titleToShare + `${urlToShare}`}
                    summary={titleToShare + `${urlToShare}`}
                    // source={urlToShare}
                    windowHeight={800}
                    windowWidth={1200}
                  >
                    <LinkedinIcon round size={36} />
                  </LinkedinShareButton>

                  <IconButton
                    sx={{
                      background: "white",
                      height: "35px",
                      width: "35px",
                    }}
                    disableRipple
                    onClick={async () => {
                      const sortedData = sortUserScores();
                      const highest = sortedData[0];
                      const lowest = sortedData[sortedData.length - 1];

                      const image = await (
                        await fetch("/demo-share-image.png")
                      ).blob();

                      const handleShare = {
                        //title: "Share Your Score!",
                        text: titleToShare + `${urlToShare}`,
                        files: [
                          new File([image], "score_share.png", {
                            type: "image/png",
                          }),
                        ],
                      };

                      try {
                        await navigator.share(handleShare);
                      } catch (err) {}
                    }}
                  >
                    <IosShareIcon
                      sx={{
                        color: "black",
                        fontSize: "18px",
                      }}
                    />
                  </IconButton>
                </div>

                <div className="mobile-share-buttons">
                  <MobileShareButton
                    class={`progress-footer__button`}
                    text={`Share`}
                    onSubmit={async () => {
                      const sortedData = sortUserScores();
                      const highest = sortedData[0];
                      const lowest = sortedData[sortedData.length - 1];
                      /* const getBase64StringFromDataURL = (dataURL) =>
                        dataURL.replace("data:", "").replace(/^.+,/, ""); */

                      const image = await (
                        await fetch("/demo-share-image.png")
                      ).blob();
                      /* const reader = new FileReader();

                      reader.onloadend = () => {
                        // Convert to Base64 string
                        const base64 = getBase64StringFromDataURL(
                          reader.result
                        );
                      };
                      reader.readAsDataURL(image); */

                      const handleShare = {
                        //title: "Share Your Score!",
                        text: titleToShare + urlToShare,
                        files: [
                          new File([image], "score_share.png", {
                            type: "image/png",
                          }),
                        ],
                      };

                      try {
                        await navigator.share(handleShare);
                      } catch (err) {}
                    }}
                  />
                </div>
              </div>
              <div className="discount">
                {(window.location.host.toLowerCase().includes("vesync") && (
                  <a
                    target="_blank"
                    href="https://us.vesync.com/"
                    rel="noreferrer"
                  >
                    {props.user.language.partner_coupons.vesync_line1}
                    <br />
                    {props.user.language.partner_coupons.vesync_line2}
                  </a>
                )) ||
                  (window.location.host.toLowerCase().includes("scp") && (
                    <a
                      target="_blank"
                      href="https://www.japa.health/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.scp}
                    </a>
                  )) ||
                  (window.location.host
                    .toLowerCase()
                    .includes("stream2sea") && (
                    <a
                      target="_blank"
                      href="https://www.japa.health/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.stream2sea}
                    </a>
                  )) ||
                  (window.location.host.toLowerCase().includes("cor") && (
                    <a
                      target="_blank"
                      href="https://cor.japa.health/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.cor}
                    </a>
                  )) || (
                    //default case(for vitality.japa.health or any other)
                    <a
                      target="_blank"
                      href="https://us.vesync.com/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.vitality_line1}
                      <br />
                      {props.user.language.partner_coupons.vitality_line2}
                    </a>
                  )}
              </div>
              <div>
                <InputLabel
                  style={{
                    cursor: "pointer",
                    color: "white",
                    paddingRight: "20px",
                  }}
                  onClick={() => {
                    localStorage.removeItem("progressDone");
                    localStorage.removeItem("detailDone");
                    localStorage.setItem("review", "true");
                    navigate("/progress");
                  }}
                >
                  Review Assessment
                </InputLabel>
              </div>
            </div>

            <div className="result-block">
              {props.user.determinantList &&
                props.user.determinantList.length > 0 && (
                  <CollapsBlock
                    deleteVisible={props.user.emailaddress
                      .toLowerCase()
                      .endsWith("@japa.health")}
                    modelOpen={onSubmitQuestion}
                    data={props.user.determinantList}
                    deleteUserData={openDeleteModal}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      <Modal open={open}>
        <Box sx={style} className="inner-success-modal w-95">
          <div className="ab-close-pop-wrap">
            <img
              src={closex}
              alt={closex}
              onClick={() => onSubmitQuestion({})}
            ></img>
          </div>
          <div className="out-card-box-wrap">
            <div className="left-image-wrap">
              <img src={heartTick} alt={heartTick}></img>
            </div>
            <div className="right-desc-wrap">
              <b className="avenir-bold">{modalData.Suggestion}</b>
              <p>{modalData.DeterminantName}</p>
            </div>
          </div>
          <div className="inner-body-pop-up-wrap">
            <p>{modalData.Blurb}</p>
            <p>
              ReferenceLink :
              <a
                href={modalData.ReferenceLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  props.updateReference(props.user._id, modalData.suggestionID);
                }}
              >
                {modalData.ReferenceLink}
              </a>
              <p>
                LMS :
                <a
                  href="https://japa.health/wordpress-dev/courses/learndash-101/"
                  target="_blank"
                  rel="lms"
                >
                  https://japa.health/wordpress-dev/courses/learndash-101/
                </a>
              </p>
            </p>
          </div>
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={deleteStyle} className="delete-inner-success-modal">
          <div className="delete-out-card-box-wrap">
            <div className="left-image-wrap text-center w-100">
              <h3>Clear And Restart?</h3>
            </div>
          </div>
          <div className="delete-inner-body-pop-up-wrap">
            <div className="button-container" style={{ display: "flex" }}>
              <ButtonInput
                class="delete-button ok-button"
                text={"Clear"}
                onSubmit={() => deleteUserData()}
              />
              <ButtonInput
                class="ok-button"
                text={"Cancel"}
                onSubmit={() => setDeleteModal(false)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

ResultBody.propTypes = {};
ResultBody.defaultProps = {};

const actionCreators = {
  getDeterminants,
  getUserData,
  deleteUserResponse,
  updateSuggestion,
  updateReference,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(ResultBody);