import React from "react";
import { Container, Typography, CircularProgress, Box } from "@mui/material";

const Loading = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <Box>
        <CircularProgress size={60} style={{ color: "#fff" }} />{" "}
        <Typography variant="h5" style={{ marginTop: "20px", color: "#fff" }}>
          {" "}
          Loading...
        </Typography>
      </Box>
    </Container>
  );
};

export default Loading;
