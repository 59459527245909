import React, { useEffect } from "react";
import Header from "../../component/header/index"; // Adjust the import according to your file structure
import "./plans.scss"; // Import a CSS file for styling
import { useNavigate } from "react-router";
import { Container, Typography, Button, Paper } from "@mui/material";

const PaymentSuccess = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("userKey")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth="sm" style={{ marginTop: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
          <Typography variant="h4" color="success.main">
            Payment Successful!
          </Typography>
          <Typography variant="body1" style={{ margin: "20px 0" }}>
            Thank you for your payment. Your transaction has been processed
            successfully.
          </Typography>

          {/* <Typography variant="h6">Order Details</Typography>
          <Typography variant="body2"><strong>Order ID:</strong> {orderId}</Typography>
          <Typography variant="body2"><strong>Amount:</strong> ${amount}</Typography> */}

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/history")}
            style={{ marginTop: "20px" }}
          >
            Continue Assessment
          </Button>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentSuccess;
