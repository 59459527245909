import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../../component/header";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Payment = (props) => {
  console.log(
    "envvv",
    process.env.REACT_APP_STRIPE_KEY,
    "-----",
    process.env.REACT_APP_CLIENT_SECRET_KEY
  );
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET_KEY;
  //   const clientSecret = "sk_test_your_test_publishable_key_here";

  // useEffect(() => {
  //   const fetchClientSecret = async () => {
  //     const response = await fetch(
  //       "http://172.16.1.106:7000/api/v1/create-payment-intent",
  //       {
  //         // Adjust the URL as needed
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({ amount: 1000 }), // Example amount in cents
  //       }
  //     );
  //     const data = await response.json();
  //     setClientSecret(data.clientSecret);
  //   };

  //   fetchClientSecret();
  // }, []);

  return (
    <>
      <Header />
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentForm />
        </Elements>
      )}
    </>
  );
};

export default Payment;
