import React, { useEffect } from "react";
import "./progress.scss";
import { connect } from "react-redux";
import { updateUserData, getUserData } from "../../redux/action/user";
import Header from "../../component/progress/header";
import ProgressBody from "../../component/progress/progressBody";
import { useNavigate } from "react-router";

const Progress = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("userKey")) {
      navigate("/");
    }
    props.getUserData(localStorage.getItem("userKey"), navigate);
  }, []);

  return (
    <>
      <Header />
      <ProgressBody />
    </>
  );
};

Progress.propTypes = {};
Progress.defaultProps = {};

const actionCreators = {
  updateUserData,
  getUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Progress);
